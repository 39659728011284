.web_checkin_btn_container {
  width: 100% !important;
  padding: 0 20px 20px 20px !important;
}

@media screen and (min-width: 768px) {
  .web_checkin_btn_container {
    width: 548px !important;
  }
}

@media screen and (min-height: 960px) {
  .web_checkin_btn_container {
    position: absolute !important;
  }
}

#language_select {
  appearance: none !important;
}