@import "./fonts.css";

.width-100-percent {
    width: 100%;
}
.width-90-percent {
    width: 90%;
}
.row-full-width {
    width: calc(100% - 16px);
    max-width: 550px;
    margin: 0 auto;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-center {
    align-items: center;
}

.flex-justify-center {
    display: flex;
    justify-content: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-justify-evenly {
    display: flex;
    justify-content: space-evenly;
}

.m-t-5 {
    margin-top: 5em;
}

.m-t-3 {
    margin-top: 3em;
}

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(255, 255, 255, 0.7);
    z-index: 10;
}

.veris_spinner {
    width: 4rem;
    height: 4rem;
}

.veris_alert {
    bottom: 0.3vh;
    width: 75%;
    float: right;
    position: absolute;
    right: 3em;
}

.hide {
    display: none;
}

.sign_head {
    text-align: center;
    font-family: 'Gilroy-Medium';
    font-size: 16px;
}

canvas.sigCanvas {
    width: 90%;
    margin: 0 auto;
    border: 1px dashed #CBCED4;
    margin-top: 1.5em;
    margin-bottom: 2em;
}

.clear_sign_btn {
    width: 20%;
    position: relative;
    bottom: 20px;
    left: 1.3em;
    background: transparent;
    border-color: #EBEDF2;
    font-family: 'Gilroy-Bold';
    color: #242934;
}

.btn-warning:not(:disabled):not(.disabled):active {
    background-color: none;
    border-color: none;
}

.react-tel-input {
    font-family: 'Gilroy-SemiBold';
}

.error_text {
    color: #FF3E1D;
    font-family: 'Glilroy-Medium';
    font-size: 14px;
}

.error_text_meeting{
    position: absolute;
    bottom: 0.5em;
    color: #FF3E1D;
    font-family: 'Glilroy-Medium';
    font-size: 14px;
}

.m-l-3em {
    margin-left: 3em;
}

.primary-button {
    background-color: #018CCF;
    border-color: #018CCF;
    color: white;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Gilroy-Bold';
}

.tc-button {
    color: #018CCF;
    border-color: #018CCF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Gilroy-Bold';
}

button.primary-button:hover {
    color: #fff !important;
}

button.tc-button:hover {
    color: #018ccf !important;
}

.primary-font-color {
    background-color: #018CCF;
}

.input-label {
    font-size: 16px;
    line-height: 18px;
    color: #878E99;
    margin-bottom: 1em;
    font-family: 'Gilroy-SemiBold';
    text-align: center;
}

.input-label-small {
    font-size: 14px;
    line-height: 18px;
    color: #878E99;
    margin: 0.5em 0;
    font-family: 'Gilroy-Medium';
    text-align: center;
}

h1 {
    font-weight: 700;
    font-size: 24px;
    color: #242934;
    text-align: center;
    margin: 1em 0 2em 0;
    padding: 0 8px;
    font-family: 'Gilroy-Bold';
}

a {
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    color: #018CCF;
}

.tnc {
    color: #878E99;
    font-size: 14px;
    text-align: center;
    font-family: 'Gilroy';
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Gilroy';
    color: #525866;
    font-size: 14px;
    opacity: 1; /* Firefox */
}

.error_input_field {
    border-color: #FF3E1D !important;
}

.error_input_field:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #FF3E1D !important;
    border-color: #FFF !important;
}

.m-b-2rem {
    margin-bottom: 2rem;
}

.tenant_sub_head {
    margin-top: 6em;
}

.search_border {
    border: 1px solid #CBCED4;
    border-radius: 5px;
}

.white-bg {
    background: #fff;
}

.search_border input:focus {
    box-shadow: none;
}

.search_box {
    margin-top: 2em;
    width: 90%;
    display: flex;
    align-self: center;
}

.search_list_icon {
    position: absolute;
    right: 0;
    color: #EBEDF2;
}

.pdf_container_div canvas {
    height: 100% !important;
    width: 100% !important;
    max-height: 500px !important;
}

.pdf_container_div {
    border: 1px solid #EBEDF2;
    width: 100%;
    margin-top: 30px;
    min-height: 520px !important;
}

.react-pdf__Page__textContent {
    display: none;
    height: 0px !important;
}
.language_selector_cmponent{
    display: flex;
}

/* media queries for responsiveness */

@media screen and (max-width: 320px) {
    .m-t-3 {
        margin-top: 2em;
    }

    .m-t-5 {
        margin-top: 3em;
    }

    h1 {
        margin-bottom: 1em;
    }

    .m-b-2rem {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 414px) and (min-width: 321px) {

}
