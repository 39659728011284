@font-face {
    font-family: 'Gilroy';
    src: url("../fonts/Gilroy/Gilroy-Regular.ttf");
    font-display: swap;
}
@font-face {
    font-family: 'Gilroy-Medium';
    src: url("../fonts/Gilroy/Gilroy-Medium.ttf");
    font-display: swap;
}
@font-face {
    font-family: 'Gilroy-SemiBold';
    src: url("../fonts/Gilroy/Gilroy-SemiBold.ttf");
    font-display: swap;
}
@font-face{
    font-family: 'Gilroy-Bold';
    src: url("../fonts/Gilroy/Gilroy-Bold.ttf");
    font-display: swap;
}